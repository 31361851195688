.container {
  padding-top: 4rem;
  padding-bottom: 6rem;
  position: relative;
}
.ttl {
  color: var(--neutral-100);
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.txt {
  color: var(--neutral-60);
  font-size: 1px;
  max-width: 550px;
  line-height: 1.6;
  margin-bottom: 4rem;
}
.stats {
  display: flex;
}
.stat {
  margin-right: 6%;
}
.stat__value {
  color: var(--neutral-100);
  font-weight: 800;
  font-size: 20px;
  border-left: 2px solid var(--primary-70);
  padding-left: 14px;
  margin-bottom: 12px;
}
.stat__text {
  color: var(--neutral-60);
  padding-left: 14px;
  font-size: 15px;
}
.pattern {
  position: absolute;
  right: 1rem;
  bottom: 6rem;
  width: 18%;
  height: auto;
  max-width: 150px;
}

@media screen and (max-width: 800px) {
  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .ttl {
    font-size: 30px;
    max-width: unset;
  }
  .txt {
    margin-bottom: 3rem;
  }
  .stats {
    display: block;
  }
  .stat {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .stat__value {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .stat__text {
    font-size: 14px;
  }
  .pattern {
    top: 4rem;
    bottom: unset;
    transform: rotate(-90deg);
    border-top-left-radius: 100%;
    width: 18%;
    max-width: 100px;
    right: 0;
  }
}

@media screen and (max-width: 400px) {
  .ttl {
    font-size: 22px;
    margin-bottom: 1.2rem;
  }
  .txt {
    font-size: 14px;
  }
  .pattern {
    width: 80px;
  }
}
