.container {
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: 4rem 2rem 2rem;
  margin-left: calc((100vw - 1180px) / 2);
  width: 40%;
  overflow: auto;
  min-width: 500px;
}
.body::-webkit-scrollbar {
  width: 0px;
}
.logoWL {
  width: 60px;
  min-height: 40px;
  object-fit: contain;
}
.logo {
  width: 120px;
  height: 40px;
  min-height: 40px;
  object-fit: contain;
}
.logoLink {
  width: fit-content;
}
.img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
  z-index: -1;
}
.mail {
  margin-top: 2rem;
  color: var(--neutral-60);
  width: fit-content;
  margin-left: auto;
}
.content {
}

@media screen and (max-width: 1200px) {
  .body {
    margin-left: 0;
  }
}

@media screen and (max-width: 800px) {
  .body {
    margin-left: 0;
    width: 100%;
    min-width: unset;
    padding: 2rem 1rem;
    max-width: 500px;
    margin: auto;
  }
  .img {
    width: 100%;
    display: none;
  }
  .logoLink {
    margin: auto;
  }
  .logo {
    width: 100px;
  }
}
